/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Bin from "components/svgs/bin"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import ImageText from "components/image-text"
import List from "components/list"
import { TileInfo, TileLink, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query WastePageQuery {
    heroImage: file(relativePath: { eq: "hero/waste.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    participationImage: file(relativePath: { eq: "hero/participation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    repair: file(relativePath: { eq: "waste/repair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ewaste: file(relativePath: { eq: "waste/ewaste.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    display: file(relativePath: { eq: "waste/display.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trash: file(relativePath: { eq: "waste/trash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const WastePage = ({ data }) => (
  <Layout
    pageTitle="Waste"
    description="Reducing waste on campus helps lower GHG emissions, less plastic pollution, less waste to landfill, cost savings and less use of natural resources."
  >
    <Title icon={<Bin />}>Waste</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          Reducing waste on campus helps us lower our environmental impact.
          Benefits include lower GHG emissions, less plastic pollution, less
          waste to landfill, cost savings and less use of natural resources.
        </Styled.p>
        <Styled.p>
          Waste is also one of the more visible dimensions of sustainability,
          making it a major concern to students, employees and the community.
        </Styled.p>
        <Styled.p>
          Mohawk is addressing waste across campus operations through
          collaboration, behaviour change programs, and reduction and recycling
          initiatives.
        </Styled.p>
      </div>
      <ImageRow>
        <Img fluid={data.repair.childImageSharp.fluid} />
        <Img className="wide" fluid={data.ewaste.childImageSharp.fluid} />
        <Img fluid={data.display.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Bin />}>
          <Fragment>
            Continuation of the Single Use Plastics Policy (2013) banning the
            sale of plastic bags and most bottled water
          </Fragment>
          <Fragment>
            Continuation and expansion of water bottle refill stations
          </Fragment>
          <Fragment>
            New centralized waste sorting stations at Fennell and Stoney Creek
            campuses
          </Fragment>
          <Fragment>
            Continuation of electronic waste recycling program
          </Fragment>
          <Fragment>
            Introduction of PaperCut<sup>&trade;</sup> software to reduce paper
            waste
          </Fragment>
          <Fragment>
            Partnering with Chartwells to replace foodservice packaging with
            recyclable and compostable materials
          </Fragment>
          <Fragment>
            Waste outreach program, anchored by Waste Reduction Week
          </Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h2 as="span">63%</Styled.h2>
            <br />
            diversion of waste from landfills in 2019
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h2 as="span">50,000+</Styled.h2>
            <br />
            pounds of electronic waste diverted since 2012
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h2 as="span">7 million+</Styled.h2>
            <br />
            plastic bottles saved from use of water refill stations since 2016
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h2 as="span">500,000+</Styled.h2>
            <br />
            plastic bags saved through campus-wide ban since 2013
          </Styled.p>
        </TileInfo>
      </TileWrapper>

      <ImageText image={data.trash.childImageSharp.fluid}>
        <Styled.h2>Centralized Waste Stations</Styled.h2>
        <Styled.p>
          New centralized waste stations were opened at the Fennell and Stoney
          Creek campuses in 2019, creating a single collection point for waste
          in the cafeterias. The new stations place a greater emphasis on
          sorting and provide organics bins and rinsing stations to help reduce
          contamination in the recycling streams. The opening of the stations
          was coupled with an education program and the introduction of new
          compostable packaging in the cafeterias.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink
        to="/participation"
        image={data.participationImage.childImageSharp.fluid}
      >
        Participation
      </TileLink>
    </Container>
  </Layout>
)

export default WastePage
